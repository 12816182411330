
export const CUSTOM_ELEMENT_NAME = "artikel-merken-herz";
export const EVENT_ADD_TO_WISHLIST = "add_to_wishlist";
export const EVENT_REMOVE_FROM_WISHLIST = "remove_from_wishlist";

const API_BASE_URL = "/api/rest/public/merkzettel/admin/artikeldetails/v2";

export function getArtikelAufMerkzettelApiUrl() {
    return `${API_BASE_URL}/artikelnummern`;
}

export function getArtikelMerkenApiUrl(artikelnummer) {
    if (artikelnummer) {
        return `${API_BASE_URL}/${artikelnummer}.html`;
    }
    return null;
}
