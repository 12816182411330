export {sendError};

function sendError(message) {
    console.log(`log-error: ${message}`);
    const event = new CustomEvent("log-error", {
        detail: {
            message
        }
    });
    window.dispatchEvent(event);
}
