export {appCallbackMerken, appCallbackEntmerken}

function appCallbackMerken(parent, matnr) {
    sendCallback(createCallbackAppElement(true, matnr), parent);
}

function appCallbackEntmerken(parent, matnr) {
    sendCallback(createCallbackAppElement(false, matnr), parent);
}

function sendCallback(element, parent) {
    parent.insertAdjacentElement("beforeend", element);
}

function createCallbackAppElement(merken, matnr) {
    const callbackElement = document.createElement("callback-app");
    const paramElement = document.createElement("callback-param");
    callbackElement.appendChild(paramElement);

    callbackElement.setAttribute("callback", merken ? "addToWishList" : "removeFromWishList");
    paramElement.setAttribute("key", "matnr");
    paramElement.setAttribute("value", matnr);

    return callbackElement;
}
