import { isNumber } from "./utils";

export {
    getDlProductElement,
    getEventParams,
    DATALAYER_ITEM_PARAMS
}

const DATALAYER_ITEM_PARAMS = ["pid", "name", "value", "currency", "category"];

function getDlProductElement(data, paramKeys) {
    const dlProduct = document.createElement("dl-product");
    const productParams = getEventParams(data, paramKeys);
    const entries = Object.entries(productParams);
    for (const [key, value] of entries) {
        dlProduct.setAttribute(key, value);
    }
    return dlProduct;
}

function getEventParams(data, params) {
    const eventParams = {};
    for (const [key, value] of data) {
        const isValidParam = params.includes(key);
        const datalayerValue = getEventValue(value);
        if (isValidParam) {
            Object.assign(eventParams, {
                [key]: datalayerValue
            })
        }
    }
    return eventParams;
}

function getEventValue(originalValue) {
    const numericValue = isNumber(originalValue);
    if (numericValue) {
        return Number(originalValue);
    }
    return originalValue;
}
