import {cookieExistiert, cookieWertAuslesen} from "../../assets/js/utils/cookies";

export {merklisteAusAppCookieAuslesen}

const COOKIE_NAME = "wishListApp";

function merklisteAusAppCookieAuslesen() {
    const gemerkteArtikel = [];
    if (cookieExistiert(COOKIE_NAME)) {
        const matnrListe = cookieWertAuslesen(COOKIE_NAME);
        for (const m of matnrListe.split(':')) {
            const matnr = m.trim();
            if (matnr !== "") {
                gemerkteArtikel.push(matnr);
            }
        }
    }
    return {gemerkteArtikel: gemerkteArtikel};
}
