import { 
    CUSTOM_ELEMENT_NAME, 
    getArtikelMerkenApiUrl,
    EVENT_ADD_TO_WISHLIST,
    EVENT_REMOVE_FROM_WISHLIST
} from "./config.js";
import { getDlProductElement, DATALAYER_ITEM_PARAMS } from "../../assets/js/datalayer.js";
import { istAppMandant, requestTimeoutSignal, sendError, sleep } from "../../assets/js/utils/index.js";
import { appCallbackEntmerken, appCallbackMerken } from "./app-callback.js";

export class ArtikelMerkenHerz extends HTMLElement {
    constructor() {
        super();
        // Jedes artikel-merken-herz feuert und hört auf das CustomEvent "merken-herz-update", 
        // damit gleiche Artikel in anderen Listen ebenfalls aktualisiert werden.
        document.addEventListener("merken-herz-update", (event) => {
            this.merkenHerzUpdateHandler(event)
        });
    }
    
    static get observedAttributes() {
        return ["data-state"];
    }

    get data() {
        return Object.entries(this.dataset);
    }

    set state(neuerWert) {
        this.setAttribute("data-state", neuerWert);
    }

    get state() {
        return this.dataset.state;
    }

    get type() {
        return this.dataset.type;
    }

    get legacy() {
        return this.getAttribute("legacyui") !== null || this.type === "list";
    }

    get merkenUrl() {
        return getArtikelMerkenApiUrl(this.artikelnummer);
    }

    get artikelnummer() {
        return this.dataset.artikelnummer;
    }

    get value() {
        return this.dataset.value;
    }

    get waehrung() {
        return this.dataset.waehrung;
    }

    get iconMarked() {
        return this.querySelector(".icon-marked");
    }
    
    get iconUnmarked() {
        return this.querySelector(".icon-unmarked");
    }

    connectedCallback() {
        this.clean();
        this.render();
        this.initSubmitHandler();
    }

    attributeChangedCallback(name, alterWert, neuerWert) {
        if (name === "data-state") {
            this.updateButton(neuerWert);
        }
    }

    clean() {
        this.innerHTML = "";
    }

    createLinkTag() {
        const link = document.createElement("LINK");
        link.href = document.querySelector(`[data-preload-css="${CUSTOM_ELEMENT_NAME}"]`).href;
        link.rel = "stylesheet";
        link.setAttribute("data-css", CUSTOM_ELEMENT_NAME);
        return link;
    }

    render() {
        this.buttonElement = this.getButtonElement();
        this.dlProductElement = getDlProductElement(this.data, DATALAYER_ITEM_PARAMS);
        this.buttonElement.appendChild(this.dlProductElement);
        this.appendChild(this.buttonElement);
        this.updateButton(this.state); 
    }

    getButtonElement() {
        const template = document.querySelector("template#sbe-merkenherz");
        const clone = template.content.cloneNode(true);
        const button = clone.querySelector(`#${this.type}`);
        button.setAttribute("value", this.value);
        return button;
    }

    initSubmitHandler() {
        const appCallbacksverwenden = istAppMandant();
        this.addEventListener("click", (event) => {
            if (event.target !== this.buttonElement) return;
            if (appCallbacksverwenden) {
                this.merkenKlickHandlerAppWebView();
            } else {
                this.merkenKlickHandler();
            }
        });
    }

    merkenKlickHandlerAppWebView() {
        if (this.state === "marked") {
            appCallbackEntmerken(this, this.artikelnummer)
            this.entmerkenErfolgreich();
        } else if (this.state === "unmarked" || this.state === undefined) {
            appCallbackMerken(this, this.artikelnummer)
            this.merkenErfolgreich();
        }
    }

    merkenKlickHandler() {
        if (this.state === "marked") {
            this.artikelEntmerken()
        } else if (this.state === "unmarked" || this.state === undefined) {
            this.artikelMerken();
        }
    }

    async artikelMerken() {
        const config = {
            method: "POST", credentials: "same-origin", signal: requestTimeoutSignal(), redirect: "error"
        };
        try {
            const response = await fetch(this.merkenUrl, config);
            if (response.status === 409) {
                this.sendeLimitHinweisEvent();
                return;
            } else if (response.status !== 200) {
                this.fehlerHinweisAnzeigen("warning-add");
                return;
            } else {
                this.merkenErfolgreich();
            }
        } catch (error) {
            this.fehlerHinweisAnzeigen("warning-add");
            return sendError(`ArtikelMerken/artikelMerken: ${error}`);
        }
        this.sendeMerkzettelUpdateEvent();
    }

    merkenErfolgreich() {
        this.state = "marked";
        this.sendeStateUpdateEvent();
        this.updateGlobalMerklisteData("marked");
    }

    async artikelEntmerken() {
        const config = {
            method: "DELETE", credentials: "same-origin", signal: requestTimeoutSignal(), redirect: "error"
        };
        try {
            const response = await fetch(this.merkenUrl, config);
            if (response.status !== 200) {
                this.fehlerHinweisAnzeigen("warning-remove");
                return;
            } else {
                this.entmerkenErfolgreich();
            }
        } catch (error) {
            this.fehlerHinweisAnzeigen("warning-remove");
            return sendError(`ArtikelEntmerken/artikelEntmerken: ${error}`);
        }
        this.sendeMerkzettelUpdateEvent();
    }

    entmerkenErfolgreich() {
        this.state = "unmarked";
        this.sendeStateUpdateEvent();
        this.updateGlobalMerklisteData("unmarked");
    }


    merkenHerzUpdateHandler(event) {
        if (event.detail.target === this) return;
        if (event.detail.artikelnummer === this.artikelnummer) {
            this.state = event.detail.state;
        }
    }

    updateButton(state) {
        if (this.buttonElement === undefined) return;
        const istAufMerkzettel = state === "marked";
        if (istAufMerkzettel) {
            this.buttonElement.setAttribute("selected", "");
        } else {
            this.buttonElement.removeAttribute("selected");
        }
        this.buttonElement.querySelector(".bookmark-label").innerText = istAufMerkzettel ? "Von meinem Merkzettel entfernen" : "Auf meinen Merkzettel";
        this.buttonElement.setAttribute("data-state", state);
        this.buttonElement.setAttribute("dl-event", istAufMerkzettel ? EVENT_REMOVE_FROM_WISHLIST : EVENT_ADD_TO_WISHLIST);
    }

    updateGlobalMerklisteData(state) {
        if (typeof window.merlisteData === "undefined") return;
        const gemerkteArtikel = window.merlisteData.gemerkteArtikel;
        if (state === "marked") {
            gemerkteArtikel.push(this.artikelnummer);
        } else {
            const index = gemerkteArtikel.indexOf(this.artikelnummer);
            if (index === -1) return;
            gemerkteArtikel.splice(index, 1);
        }
    }

    sendeLimitHinweisEvent() {
        const limitHinweisEvent = new CustomEvent("merkzettel-limit-hinweis");
        document.dispatchEvent(limitHinweisEvent);
    }

    sendeMerkzettelUpdateEvent() {
        const updateEvent = new CustomEvent("merkzettel.update", {
            detail: {
                target: this, artikelnummer: this.artikelnummer, state: this.state
            }
        });
        window.dispatchEvent(updateEvent);
    }

    sendeStateUpdateEvent() {
        const stateUpdateEvent = new CustomEvent("merken-herz-update", {
            detail: {
                target: this, artikelnummer: this.artikelnummer, state: this.state,
            }
        });
        document.dispatchEvent(stateUpdateEvent);
    }

    async fehlerHinweisAnzeigen(messageid) {
        const hinweis = document.createElement("merkzettel-hinweis");
        hinweis.render(this, messageid);
        await sleep(100);
        hinweis.open();
    }
}

if (!customElements.get(CUSTOM_ELEMENT_NAME)) {
    customElements.define(CUSTOM_ELEMENT_NAME, ArtikelMerkenHerz);
}
