import { merklisteAusAppCookieAuslesen } from "./app-merkliste";
import { CUSTOM_ELEMENT_NAME, getArtikelAufMerkzettelApiUrl } from "./config.js";
import { fetchRetry } from "../../assets/js/utils";

window.merklisteData = [];

export async function merkenHerzenZustaendeSetzen(appMerkzettelProviderVerwenden) {
    if (appMerkzettelProviderVerwenden) {
        merklisteData = merklisteAusAppCookieAuslesen();
    } else {
        merklisteData = await merklisteHolen();
    }
    const alleMerkenHerzen = document.querySelectorAll(CUSTOM_ELEMENT_NAME);
    zustandHandler(alleMerkenHerzen);
    initMerkenHerzMutationObserver();
}

function initMerkenHerzMutationObserver() {
    const config = {
        attributes: false, childList: true, subtree: true
    };
    const merklisteDomObserver = new MutationObserver(mutationHandler);
    merklisteDomObserver.observe(document.body, config);
}

function mutationHandler(mutationsList, observer) {
    const nachgeladeneMerkenHerzen = document.querySelectorAll(`${CUSTOM_ELEMENT_NAME}:not([data-state])`) || [];
    zustandHandler(nachgeladeneMerkenHerzen);
}

function zustandHandler(merkenHerzen) {
    if (merkenHerzen.length === 0) return;
    for (const element of merkenHerzen) {
        pruefenUndZustandSetzen(element);
    }
}

function pruefenUndZustandSetzen(element) {
    const artikelnummer = element.dataset["artikelnummer"];
    if (typeof merklisteData === "undefined") return;
    if (merklisteData.gemerkteArtikel.indexOf(artikelnummer) === -1) {
        element.setAttribute("data-state", "unmarked");
        element.removeAttribute("selected");
        return;
    }
    element.setAttribute("data-state", "marked");
    element.setAttribute("selected", "");
}

async function merklisteHolen() {
    const url = getArtikelAufMerkzettelApiUrl();
    const config = {
        method: "GET"
    };
    try {
        const response = await fetchRetry(url, config, 3, 1500);
        if (response.status !== 200) {
            throw new Error("Die Merkliste konnte nicht abgefragt werden.");
        }
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}
