import { sleep } from "./timeout";

export {
    fetchRetry,
    requestTimeoutSignal
}

/**
 * Custom fetch mit Retry
 * @param {string} uri - URI zur Resource
 * @param {object} options - fetch options
 * @param {number} n - Anzahl der Versuche/Retries
 * @param {number} ms - Verzögerung zwischen den Versuchen in Millisekunden
 * @returns {object}
 */
 function fetchRetry(url, options, n, ms = 1000) {
    return fetch(url, options)
        .then((response) => {
            if (response.ok) return response;
            throw new Error("Network response was not ok");
        })
        .catch(async (error) => {
            console.log(`Verbleibende Versuche: ${n - 1}`)
            if (n === 1) throw new Error(error);
            await sleep(ms);
            return fetchRetry(url, options, n - 1);
        })
};

function requestTimeoutSignal() {
    const controller = new AbortController();
    const timeout = 5000;
    setTimeout(() => {
        controller.abort();
    }, timeout);
    return controller.signal;
}
