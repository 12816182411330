export { istAppMandant }

function istAppMandant() {
    const mandantId = getMandantId();
    if (mandantId === null) {
        return false;
    }
    const appMandanten = ["2003", "2005", "4002", "4003", "37002", "37004", "51003"];
    return appMandanten.indexOf(mandantId) !== -1;
}

function getMandantId() {
    const metaElementMandant = document.querySelector('meta[name=mandant]');
    return metaElementMandant != null ? metaElementMandant.content : null;
}

