export {
    cookieExistiert,
    cookieWertAuslesen
}

/**
 * Gibt true zurück, wenn das Cookie existiert
 * @param {string} name - Name des Cookies
 * @returns {boolean}
 */
function cookieExistiert(name) {
    return !!document.cookie
        .split(';')
        .filter((item) => item
            .includes(name)).length;
}

function cookieWertAuslesen(name) {
    const cookieArray = document.cookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
        const cookiePair = cookieArray[i].split("=");
        if (name === cookiePair[0].trim()) {
            return cookiePair[1];
        }
    }
    return null;
}
